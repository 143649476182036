import _assign from 'lodash/assign.js'
import useOrderStore from '~/stores/order'
import ChooseTourTypeModal from '@/components/modals/ChooseTourType.vue'
import LocationModal from '@/components/modals/Location.vue'
import LocationNotSupportedModal from '@/components/modals/LocationNotSupported.vue'
import dayjs from 'dayjs'

export function useOrderTools() {
    const showModal = inject('showModal')
    const showDialog = inject('showDialog')
    const orderStore = useOrderStore()
    const i18n = useI18n()
    const router = useRouter()

    function taxNote(
        taxRate = 19,
        customerGroup = orderStore.settings.customerGroup,
    ) {
        const mode =
            customerGroup === Enums.customerGroups.private
                ? 'included'
                : 'excluded'
        return i18n.order.taxNote(taxRate)[mode]
    }

    function monthNameGerman(date) {
        const month = date.format('MMMM')
        switch (month) {
            case 'January':
                return 'Januar'
            case 'February':
                return 'Februar'
            case 'March':
                return 'März'
            case 'April':
                return 'April'
            case 'May':
                return 'Mai'
            case 'June':
                return 'Juni'
            case 'July':
                return 'Juli'
            case 'August':
                return 'August'
            case 'September':
                return 'September'
            case 'October':
                return 'Oktober'
            case 'November':
                return 'November'
            case 'December':
                return 'Dezember'
        }
    }

    function showLocationModal(options = { scrollable: false }) {
        return new Promise((resolve, _reject) => {
            let result
            showModal(
                {
                    component: LocationModal,
                    params: { ...options?.params },
                    handleResult(value) {
                        result = value
                    },
                    afterHide() {
                        resolve(result)
                    },
                },
                options,
            )
        })
    }

    function showLocationNotSupportedModal(options = {}) {
        return new Promise((resolve) => {
            if (orderStore.isCurrentlyBookable) {
                showModal(
                    {
                        component: LocationNotSupportedModal,
                        params: {
                            zipCode: orderStore.settings.zipCode,
                            customerGroup: orderStore.settings.customerGroup,
                        },
                        afterHide: resolve,
                    },
                    _assign({ closeMethods: ['button'] }, options),
                )
            } else {
                const today = dayjs()
                const fourteenDaysInTheFuture = today.add(14, 'day')
                let startOfNextPossibleMonth
                if (today.month() === fourteenDaysInTheFuture.month()) {
                    startOfNextPossibleMonth = today
                        .add(1, 'month')
                        .startOf('month')
                } else {
                    startOfNextPossibleMonth = today
                        .add(2, 'month')
                        .startOf('month')
                }

                let backCalculationDate = startOfNextPossibleMonth
                while (
                    backCalculationDate.day() === 0 ||
                    backCalculationDate.day() === 6
                ) {
                    backCalculationDate = backCalculationDate.add(1, 'day')
                }
                const ordersPossibleFrom = backCalculationDate
                    .subtract(13, 'day')
                    .format('DD.MM.YYYY')
                const nextPossibleMonthName =
                    monthNameGerman(backCalculationDate)
                showDialog(
                    'Kapazitätsgrenze:',
                    `Bedauerlicherweise sind derzeit keine weiteren Buchungen möglich. \n
                    Bitte versuche es ab dem ${ordersPossibleFrom} erneut.
                    Von diesem Tag an werden täglich weitere Optionen für den ${nextPossibleMonthName} freigeschaltet.`,
                    [],
                    { isSupportLinkRequired: true },
                )
            }
        })
    }

    function showChooseTourTypeModal(options = {}, redirect = false) {
        return new Promise((resolve) => {
            let result
            orderStore.$patch({
                settings: {
                    tourType: '',
                },
            })

            showModal({
                component: ChooseTourTypeModal,
                params: {},
                handleResult(value) {
                    result = value
                },
                afterHide() {
                    orderStore.$patch({
                        settings: {
                            tourType: result,
                        },
                    })

                    if (result && redirect) {
                        router.push({
                            name: 'order',
                            query: orderStore.changedSettings,
                        })
                    }

                    resolve(result)
                },
                options,
            })
        })
    }

    return {
        store: orderStore,
        taxNote,
        showLocationModal,
        showLocationNotSupportedModal,
        showChooseTourTypeModal,
    }
}
